import { useAuth } from "../../auth/AuthContext";
import { RiskInfo, RiskAds, Layout } from "../../components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../libs/constants";

const Home = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const authenticateWithGovData = async () => {
      const params = new URLSearchParams(location.search);
      const appId = params.get("appId");
      const mToken = params.get("mToken");

      if (appId && mToken) {
        setLoading(true);
        try {
          console.log("appId:", appId);
          console.log("mToken:", mToken);

          const response = await axios.post(
            `${API_URL}/thaid/landing`,

            {
              appId,
              mToken,
            }
          );

          if (response.data && response.data.data) {
            localStorage.setItem(
              "auth_token",
              `Bearer ${response.data.data.accessToken}`
            );
            localStorage.setItem(
              "refresh_token",
              response.data.data.refreshToken
            );

            if (response.data.data.user) {
              localStorage.setItem(
                "user",
                JSON.stringify(response.data.data.user)
              );
            }

            window.location.href = "/";
          } else {
            throw new Error("Authentication failed");
          }
        } catch (error) {
          console.error("Authentication error:", error);

          if (
            error.response?.data?.errors &&
            error.response.data.errors.length > 0
          ) {
            const apiError = error.response.data.errors[0];
            setError(apiError.message || "Authentication failed");
          } else {
            setError(
              error.response?.data?.message ||
                error.message ||
                "Authentication failed"
            );
          }
        } finally {
          setLoading(false);
        }
      }
    };

    authenticateWithGovData();
  }, [location, navigate]);

  if (loading) {
    return (
      <div className="container mx-auto px-4 flex justify-center items-center min-h-screen">
        <div className="max-w-lg w-full p-8 bg-white rounded-lg shadow-lg flex flex-col items-center">
          <h2 className="text-xl font-semibold mb-4 text-center">
            {t("logging_in")}
          </h2>
          <div className="my-6 flex justify-center w-full">
            <div
              style={{
                width: "48px",
                height: "48px",
                border: "5px solid #f3f3f3",
                borderTop: "5px solid #3b82f6",
                borderRadius: "50%",
                animation: "spin 1s linear infinite",
                margin: "0 auto",
              }}
            />
            <style>
              {`
                @keyframes spin {
                  0% { transform: rotate(0deg); }
                  100% { transform: rotate(360deg); }
                }
              `}
            </style>
          </div>
          <p className="text-sm text-gray-500 text-center">
            {t("waiting_for_verification")}
          </p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4">
        <div className="max-w-lg mx-auto mt-16 p-8 bg-white rounded-lg shadow-lg flex flex-col items-center">
          <h2 className="text-xl font-semibold mb-4 text-red-600">
            {t("error_message")}
          </h2>
          <div className="text-center mt-2">
            <p className="mb-4">{error}</p>
            <button
              className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded transition-colors"
              onClick={() => navigate("/login")}
            >
              {t("back_to_login")}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Layout>
      {isAuthenticated && <RiskInfo />}
      <RiskAds isAuthenticated={isAuthenticated} />
    </Layout>
  );
};

export default Home;
